// import { REQUEST_PREFIX } from '@/utils/constant/menuConst'

export function getOption() {
  return {
    column: [
      {
        label: '上级菜单',
        prop: 'parentId',
        type: 'cascader',
        value: 0,
        showAllLevels: false,
        checkStrictly: true,
        emitPath: false,
        dicData: this.data,
        props: {
          label: 'menuName',
          value: 'id'
        }
      },
      {
        label: '排序',
        prop: 'sortNumber'
      },
      {
        label: 'JSON',
        prop: 'json',
        type: 'textarea',
        minRows: 20
      }
    ]
  }
}

// export const example = {
//   menuType: 0,
//   menuName: '我的产品-test',
//   routePath: 'myProduct',
//   componentPath: 'Layout',
//   permissionCode: `${REQUEST_PREFIX}:myProduct`,
//   // requestUrlRegexp: '',
//   // isShow: 1,
//   // menuStatus: 1,
//   // isPermissionControl: 1,
//   // remark: '',
//   children: [
//     {
//       menuName: '新增',
//       permissionCode: `:add`,
//       requestUrlRegexp: `/myProduct/add`,
//       children: [
//         {
//           menuName: '新增1',
//           permissionCode: `:add1`,
//           requestUrlRegexp: `/myProduct/add1`
//         },
//         {
//           menuName: '新增2',
//           permissionCode: `:add2`,
//           requestUrlRegexp: `/myProduct/add2`
//         },
//         {
//           menuName: '新增3',
//           permissionCode: `:add3`,
//           requestUrlRegexp: `/myProduct/add3`
//         },
//       ]
//     },
//     {
//       menuName: '删除',
//       permissionCode: `:delete`,
//       requestUrlRegexp: `/myProduct/delete`
//     },
//     {
//       menuName: '修改',
//       permissionCode: `:edit`,
//       requestUrlRegexp: `/myProduct/edit`
//     }
//   ]
// }

export const example = {
  menuType: 0,
  menuName: '全部订单',
  routePath: 'allOrder',
  componentPath: 'Layout',
  permissionCode: `allOrder`,
  children: [
    {
      menuName: '待关联',
      menuType: 0,
      permissionCode: `:waitRelated`,
      requestUrlRegexp: `/allOrder/waitRelated`,
      children: [
        {
          menuName: '详情',
          permissionCode: `:detail`,
          requestUrlRegexp: `/allOrder/waitRelated/detail`
        },
        {
          menuName: '导出',
          permissionCode: `:export`,
          requestUrlRegexp: `/allOrder/waitRelated/export`
        },
        {
          menuName: '删除',
          permissionCode: `:del`,
          requestUrlRegexp: `/allOrder/waitRelated/del`
        }
      ]
    },
    {
      menuName: '待支付',
      menuType: 0,
      permissionCode: `:waitPay`,
      requestUrlRegexp: `/allOrder/waitPay`,
      children: [
        {
          menuName: '详情',
          permissionCode: `:detail`,
          requestUrlRegexp: `/allOrder/waitPay/detail`
        },
        {
          menuName: '导出',
          permissionCode: `:export`,
          requestUrlRegexp: `/allOrder/waitPay/export`
        },
        {
          menuName: '删除',
          permissionCode: `:del`,
          requestUrlRegexp: `/allOrder/waitPay/del`
        }
      ]
    },
    {
      menuName: '排单中',
      menuType: 0,
      permissionCode: `:inDispatch`,
      requestUrlRegexp: `/allOrder/inDispatch`,
      children: [
        {
          menuName: '详情',
          permissionCode: `:detail`,
          requestUrlRegexp: `/allOrder/inDispatch/detail`
        },
        {
          menuName: '导出',
          permissionCode: `:export`,
          requestUrlRegexp: `/allOrder/inDispatch/export`
        },
        {
          menuName: '删除',
          permissionCode: `:del`,
          requestUrlRegexp: `/allOrder/inDispatch/del`
        }
      ]
    },
    {
      menuName: '已完成',
      menuType: 0,
      permissionCode: `:finish`,
      requestUrlRegexp: `/allOrder/finish`,
      children: [
        {
          menuName: '详情',
          permissionCode: `:detail`,
          requestUrlRegexp: `/allOrder/finish/detail`
        },
        {
          menuName: '导出',
          permissionCode: `:export`,
          requestUrlRegexp: `/allOrder/finish/export`
        },
        {
          menuName: '删除',
          permissionCode: `:del`,
          requestUrlRegexp: `/allOrder/finish/del`
        }
      ]
    }
  ]
}
